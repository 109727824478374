import axios from '@/plugins/axios'

export default {
  async login(data) {
    return await axios.post('login', data)
  },

  async loginCustomer(data) {
    return await axios.post('login-customer', data)
  },

  async checkLogin(data) {
    return await axios.get('check-login', data)
  },
}
